import logo from './logo.svg';
import Typewriter from 'typewriter-effect';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="header">
        <img src={logo} className="logo" alt="logo" />
          <Typewriter
              options={{
                  strings: ['Technical Advisory', 'Complex Project Delivery',
                            'MVP Development', 'Due Diligence', 'Fractional CTO'],
                  autoStart: true,
                  loop: true,
                  deleteSpeed: 0.2
              }}
          />
      </header>
    </div>
  );
}

export default App;
